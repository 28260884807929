<template lang="pug">
include ../../../configs/template
div.row.text-left
  div.col-12
    +data-info('createDate', 'statement.created_at')
  div.col-12
    +data-info('delivery', 'postService')
  div(v-if="statement.delivery").text-left.p-0
    div(v-if="statement.delivery && statement.delivery.is_courier && !statement.morrichservice_office").col-12
      +data-info('address', 'address')
    div(v-if="!statement.morrichservice_office && !statement.delivery.is_courier").col-12
      +data-info('department', 'department')
    div.col-12(v-if="statement.delivery && statement.delivery.phone_number")
      +data-info('phoneNumber', 'statement.delivery.phone_number')
  div(v-else-if="statement.morrichservice_office").pl-4.pb-4
    +data-info('department', 'getDeliveryObject({value: "listWearHouseMorrichservice", id: statement.morrichservice_office})[nameLang]')

  div.col-12
    +data-info('payment', 'paymentStatusByStatus(statement.is_payed)[0][nameLang]')
  div.col-12
    +data-info('createdBy', 'statement.created_by.name')
  div.col-12
    +data-info('status', 'statusDocumentName')(:class="getStatus(statement.status_document)")
  div(v-if="statement.comments?.length").col-12.text-left
    div
      h5.p-0.m-0 {{$t('rejectionReasonStatement')}}:
      v-divider
      div(v-for="(comment, index) in statement.comments" :key="comment.id").ml-4
        div
          +data-info('rejectionReasonStatement','getDirectoryObject({ id: comment.additional_info.reason, value: "rejectionReasons" })[nameLang]')
        div(v-if="checkAccess('backOffice')").pt-2.py-3
          +data-info('createdBy', 'comment.created_by.name')
        div.pt-2.py-3
          +data-info('dateCreate', 'new Date(comment.created_by.date).toLocaleString()')
        div.pt-2.py-3
          +data-info('reasonComment', 'comment.comment')
        v-divider(v-if="index !== statement.comments.length-1")
</template>

<script>
import { getDirectoryObject, getStatus } from '@/mixins/main'
import { mapGetters, mapState } from 'vuex'
import { checkAccess } from '@/mixins/permissions'

export default {
  name: 'SailorRecordBookStatementInfo',
  props: {
    statement: { type: Object, default: () => ({}) },
    comments: { type: Array, default: () => ([]) }
  },
  data () {
    return {
      getStatus,
      checkAccess,
      rejectStatusId: 13
    }
  },
  computed: {
    ...mapGetters(['paymentStatusByStatus', 'rejectionReasonByID', 'getDeliveryObject', 'getDirectoryObject']),
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      listDeliveryTypes: state => state.directory.deliveryTypeList
    }),
    verificationRefusalReason () {
      return this.comments.find(item => item.additional_info.cancel_reason)
    },
    verificationRefusalReasonName () {
      return this.rejectionReasonByID(this.verificationRefusalReason.additional_info.reason)[this.nameLang]
    },
    statusDocumentName () {
      return getDirectoryObject({ value: 'statuses', id: this.statement.status_document })[this.nameLang]
    },
    address () {
      return `${this.statement.delivery?.area || ''} ${this.statement.delivery?.city || ''} ${this.statement.delivery?.street || ''}
        ${this.statement.delivery?.house || ''} ${this.statement.delivery?.apartment || ''}`
    },
    department () {
      return `${this.statement.delivery.area}, ${this.statement.delivery.city} ${this.statement.delivery.warehouse}`
    },
    postService () {
      return this.listDeliveryTypes.find(item => this.statement.pickup_from_morrichservice
        ? item.id === 3 : item.courier === this.statement.delivery.is_courier)[this.nameLang]
    }
  }
}

</script>
